@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --size-s: 0.75rem;
    --size-m: 0.875rem;
    --size-l: 1rem;
    --size-2xl: 1.25rem;
    --size-3xl: 1.5rem;

    --line-height-s: 1rem;
    --line-height-m: 1.25rem;
    --line-height-l: 1.5rem;
    --line-height-2xl: 1.75rem;
    --line-height-3xl: 2rem;

    /* semantic */
    --text-s-size: var(--size-s);
    --text-s-line-height: var(--line-height-s);

    --text-m-size: var(--size-m);
    --text-m-line-height: var(--line-height-m);

    --text-l-size: var(--size-l);
    --text-l-line-height: var(--line-height-l);

    --heading-2xs-size: var(--size-s);
    --heading-2xs-line-height: var(--line-height-s);

    --heading-xs-size: var(--size-m);
    --heading-xs-line-height: var(--line-height-m);

    --heading-m-size: var(--size-2xl);
    --heading-m-line-height: var(--line-height-2xl);
  }

  @media screen('md') {
    :root {
      --heading-2xs-size: var(--size-m);
      --heading-2xs-line-height: var(--line-height-m);

      --heading-xs-size: var(--size-l);
      --heading-xs-line-height: var(--line-height-l);

      --heading-m-size: var(--size-3xl);
      --heading-m-line-height: var(--line-height-3xl);
    }
  }
}

@layer components {
  .input-border {
    @apply border border-secondary md:hover:border-primary outline-none transition;
  }

  .input-border:focus-within {
    @apply shadow-border-brand;
  }

  .input-border:focus-within,
  .input-border[aria-selected='true'] {
    @apply !border-brand;
  }

  .input-border[aria-selected='true'] {
    @apply bg-fill-brand-tertiary;
  }

  .car-hero-slider {
    @apply appearance-none outline-0 my-[7px];
  }

  .car-hero-slider::-webkit-slider-runnable-track {
    @apply rounded-full;
    background: linear-gradient(
      to right,
      theme('colors.fill-brand-secondary') 0%,
      theme('colors.fill-brand-secondary') var(--slider-value-percent),
      theme('backgroundColor.fill-brand-tertiary') var(--slider-value-percent),
      theme('backgroundColor.fill-brand-tertiary') 100%
    );
    height: 6px;
  }

  .car-hero-slider::-moz-range-track {
    @apply rounded-full;
    background: linear-gradient(
      to right,
      theme('colors.fill-brand-secondary') 0%,
      theme('colors.fill-brand-secondary') var(--slider-value-percent),
      theme('backgroundColor.fill-brand-tertiary') var(--slider-value-percent),
      theme('backgroundColor.fill-brand-tertiary') 100%
    );
    height: 6px;
  }

  .car-hero-slider::-ms-thumb {
    @apply rounded-full;
    background: linear-gradient(
      to right,
      theme('colors.fill-brand-secondary') 0%,
      theme('colors.fill-brand-secondary') var(--slider-value-percent),
      theme('backgroundColor.fill-brand-tertiary') var(--slider-value-percent),
      theme('backgroundColor.fill-brand-tertiary') 100%
    );
    height: 6px;
  }

  .car-hero-slider::-webkit-slider-thumb {
    @apply appearance-none size-5 -mt-[7px] bg-fill-on-primary border border-solid border-primary rounded-full;
  }

  .car-hero-slider::-moz-range-thumb {
    @apply appearance-none size-5 -mt-[7px] bg-fill-on-primary border border-solid border-primary rounded-full;
  }

  .car-hero-slider::-ms-track {
    @apply appearance-none size-5 -mt-[7px] bg-fill-on-primary border border-solid border-primary rounded-full;
  }
}
