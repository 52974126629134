.desktopPrimaryHeader {
  grid-template-columns: 3.5em 11rem auto auto 40px 40px 7rem;
  grid-template-areas: 'menu logo search categories cart account language';
}

.tabletPrimaryHeaderWithCart {
  grid-template-columns: 3.5em 11rem auto 40px 40px 40px 7rem;
  grid-template-areas: 'menu logo . search cart account language';
}

.tabletPrimaryHeaderWithoutCart {
  grid-template-columns: 3.5em 11rem auto 40px 40px 7rem;
  grid-template-areas: 'menu logo . search account language';
}

.tabletPrimaryHeaderCheckout {
  grid-template-columns: 3.5em 11rem;
  grid-template-areas: 'menu logo';
}

.mobilePrimaryHeader {
  grid-template-columns: 150px auto;
  grid-template-areas: 'logo cart';
}
